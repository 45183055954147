.adminSidePanel {
    text-align: left;
}
.adminSidePanel ul {
    padding-left: 0;
    margin-bottom: 0;
}

.adminSidePanel ul .MuiButtonBase-root.MuiListItem-root {
    padding: 0;
}

.adminSidePanel ul .MuiButtonBase-root.MuiListItem-root a {
    padding: 8px 16px;
}

/* .adminSidePanel {
    padding-left: 15px;
} */

.adminSidePanel .subLinks {
    width: 100%;
}

.adminSidePanel .userDD .MuiButton-label {
    text-transform: none;
    font-weight: 600;
    color: #fff;
}

.adminSidePanel .sidePanelLinks .MuiButtonBase-root.MuiListItem-root a {
    color: #e6e6e6;
    font-size: 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    width: 100%;
}

.adminSidePanel .sidePanelLinks .MuiButtonBase-root.MuiListItem-root a.active {
    color: #fff;
    background: var(--primColor);
}

.adminSidePanel .sidePanelLinks .MuiButtonBase-root.MuiListItem-root .material-icons-round,
.adminSidePanel .sidePanelLinks .MuiButtonBase-root.MuiListItem-root span {
    padding-right: 10px;
}

.adminSidePanel .dashboardHome {
    height: 80px;
    display: flex;
    align-items: center;
}

.adminSidePanel .MuiButtonBase-root.MuiAccordionSummary-root,
.adminSidePanel .MuiAccordionDetails-root {
    width: 100%;
    background: transparent;
    color: #e6e6e6;
}

.adminSidePanel .subLinks,
.adminSidePanel .subLinks .MuiButtonBase-root.MuiListItem-button {
    width: 100%;
}

.adminSidePanel .subLinks .MuiListItem-button a {
    padding: 5px 15px !important;
}

.adminSidePanel .MuiAccordion-root.Mui-expanded {
    margin: 0;
}

.adminSidePanel .MuiButtonBase-root.MuiAccordionSummary-expandIcon {
    color: #e6e6e6;
}

.adminSidePanel .MuiPaper-elevation1 {
    box-shadow: none;
    background: transparent;
}

.adminSidePanel .MuiAccordion-root:before {
    content: none;
}

.adminSidePanel .MuiPaper-root.MuiDrawer-paper .MuiDivider-root {
    background-color: rgb(255 255 255 / 12%);
}

.adminSidePanel .MuiPaper-root.MuiDrawer-paper .MuiListItem-button {
    padding: 0;
}

.adminSidePanel .MuiPaper-root.MuiDrawer-paper button {
    color: #f3f3f3;
}

.adminSidePanel .MuiPaper-root.MuiDrawer-paper .MuiListItem-button a {
    color: #f3f3f3;
    padding: 10px 15px;
    width: 100%;
}

.adminSidePanel .MuiPaper-root.MuiDrawer-paper .MuiListItem-button a:hover {
    text-decoration: none;
}

.adminSidePanel .MuiListItem-button a.active,
.adminSidePanel .MuiButtonBase-root.MuiAccordionSummary-root.active {
    /* background: var(--primColor); */
    /* filter: saturate(0.6); */
}

.adminSidePanel .MuiAccordionSummary-root.Mui-expanded {
    min-height: 52px;
    height: 52px;
}

.adminSidePanel .MuiAccordionSummary-root.Mui-expanded .Mui-expanded {
    color: var(--primColor);
}

.adminSidePanel .subLinks .MuiListItem-button a.active {
    background: transparent;
    color: var(--primColor);
}

.adminSidePanel .MuiListItemText-root .MuiTypography-root {
    font-weight: 400;
    display: flex;
    align-items: center;
}

.adminSidePanel .sidePanelLinks .subLinks .MuiButtonBase-root.MuiListItem-root a {
    padding-left: 35px !important;
}
