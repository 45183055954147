.MuiTab-root,
.MuiTableCell-root {
    font-family: var(--fontFamily) !important;
}

.MuiPickersDay-daySelected {
    background-color: var(--primColor) !important;
}

.MuiDialog-root .MuiButton-textPrimary {
    color: var(--primColor) !important;
}

.MuiInput-underline:after,
.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: var(--primColor) !important;
}

.accordion {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    min-width: 80%;
}

.accordion .MuiAccordion-root {
    width: 80%;
    padding: 0 0 0 20px;
}

.accordion .accordion_title {
    width: 100%;
    font-size: 20px;
    color: #3d3b3b;
    font-weight: 700;
}

.body {
    background-color: #f6f6f6;
    width: 100%;
}

.body .button {
    padding: 1rem;
    margin-right: 10px;
    min-width: 140px;
}

.body .text {
    padding: 1rem 0;
}

.payout {
    width: 100%;
    margin: 0 20px 20px 0;
}

.notes_subtitle,
.payout .payout_subtitle {
    font-weight: bold;
    margin-bottom: 1rem;
}

.payout .payout_toggle {
    border: 1px solid grey;
    border-radius: 4px;
    width: 100%;
    margin-right: 20px;
    padding: 1rem;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.payout .payout_tooglebtn {
    padding-top: 1rem;
}

.payout .payout_tooglebtn .Mui-checked {
    color: var(--primColor);
}

.notes {
    width: 100%;
    margin-right: 20px;
}

.table {
    width: 100%;
    margin: 0 20px 20px 0;
}

.table .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
}

/* .table .table_container th,
td {
    border: 1px solid rgba(224, 224, 224, 1);
} */

.table .table_container th:not(:first-child) {
    width: 20%;
}

.item_footer {
    display: flex;
    justify-content: space-between;
}

.item_footer .btn_group {
    align-self: flex-start;
}

.item_footer .btn_group .btn_group1 button {
    color: var(--primColor);
}

.item_footer .total {
    align-self: flex-end;
    font-size: 20px;
    color: #3d3b3b;
    font-weight: 700;
}

.item_footer .btn_group .btn_details {
    display: flex;
    align-content: stretch;
    margin-top: 1rem;
}

.item_footer .btn_group .btn_details .btn_apply {
    margin-left: 1rem;
}

.item_footer .btn_group .btn_details svg {
    margin-left: 1rem;
    align-self: center;
}

.tabs button {
    background-color: #eeeaea;
    margin-right: 1rem;
    border-radius: 5px;
}

.tabs .Mui-selected {
    color: #fff;
    background-color: var(--primColor);
}

.body .tabpanel .MuiBox-root {
    padding: 0;
}

.body .btn_group_tablist {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 100;
    background-color: #f6f6f6;
}

.body .btn_group_tablist a {
    text-decoration: none;
}

.body .btn_group_tablist a:active {
    background-color: var(--primColor);
    color: #fff;
}

.body .btn_group_tablist button {
    margin: 0 10px;
    min-width: 129px;
}
