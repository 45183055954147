.dashboardSidePanel {
    text-align: left;
}
.dashboardSidePanel ul {
    padding-left: 0;
    margin-bottom: 0;
}

.dashboardSidePanel ul .MuiButtonBase-root.MuiListItem-root {
    padding: 0;
}

.dashboardSidePanel ul .MuiButtonBase-root.MuiListItem-root a {
    padding: 8px 16px;
}

.dashboardSidePanel {
    padding-left: 15px;
}

.dashboardSidePanel .subLinks {
    width: 100%;
}

.dashboardSidePanel .userDD .MuiButton-label {
    text-transform: none;
    font-weight: 600;
}

.dashboardSidePanel .sidePanelLinks .MuiButtonBase-root.MuiListItem-root a {
    color: #3d3b3b;
    font-size: 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    width: 100%;
}

.dashboardSidePanel .sidePanelLinks .MuiButtonBase-root.MuiListItem-root a.active {
    color: var(--primColor);
}

.dashboardSidePanel .sidePanelLinks .MuiButtonBase-root.MuiListItem-root .material-icons-round,
.dashboardSidePanel .sidePanelLinks .MuiButtonBase-root.MuiListItem-root span {
    padding-right: 10px;
}

.dashboardSidePanel .dashboardHome {
    height: 80px;
    display: flex;
    align-items: center;
}

.dashboardSidePanel .MuiButtonBase-root.MuiAccordionSummary-root,
.dashboardSidePanel .MuiAccordionDetails-root {
    width: 100%;
    background: transparent;
}

.dashboardSidePanel .subLinks,
.dashboardSidePanel .subLinks .MuiButtonBase-root.MuiListItem-button {
    width: 100%;
}

.dashboardSidePanel .subLinks .MuiListItem-button a {
    padding: 5px 15px !important;
}

.dashboardSidePanel .MuiAccordion-root.Mui-expanded {
    margin: 0;
}

.dashboardSidePanel .MuiButtonBase-root.MuiAccordionSummary-expandIcon {
    color: #3d3b3b;
}

.dashboardSidePanel .MuiPaper-elevation1 {
    box-shadow: none;
    background: transparent;
}

.dashboardSidePanel .MuiAccordion-root:before {
    content: none;
}

.dashboardSidePanel .MuiPaper-root.MuiDrawer-paper .MuiDivider-root {
    background-color: rgb(255 255 255 / 12%);
}

.dashboardSidePanel .MuiPaper-root.MuiDrawer-paper .MuiListItem-button {
    padding: 0;
}

.dashboardSidePanel .MuiPaper-root.MuiDrawer-paper button {
    color: #f3f3f3;
}

.dashboardSidePanel .MuiPaper-root.MuiDrawer-paper .MuiListItem-button a {
    color: #f3f3f3;
    padding: 10px 15px;
    width: 100%;
}

.dashboardSidePanel .MuiPaper-root.MuiDrawer-paper .MuiListItem-button a:hover {
    text-decoration: none;
}

.dashboardSidePanel .MuiListItem-button a.active,
.dashboardSidePanel .MuiButtonBase-root.MuiAccordionSummary-root.active {
    /* background: var(--primColor); */
    /* filter: saturate(0.6); */
}

.dashboardSidePanel .MuiAccordionSummary-root.Mui-expanded {
    min-height: 52px;
    height: 52px;
}

.dashboardSidePanel .MuiAccordionSummary-root.Mui-expanded .Mui-expanded {
    color: var(--primColor);
}

.dashboardSidePanel .subLinks .MuiListItem-button a.active {
    background: transparent;
    color: var(--primColor);
}

.dashboardSidePanel .MuiListItemText-root .MuiTypography-root {
    font-weight: 400;
    display: flex;
    align-items: center;
}

.dashboardSidePanel .sidePanelLinks .subLinks .MuiButtonBase-root.MuiListItem-root a {
    padding-left: 35px !important;
}
