:root {
    --fontFamily: 'Mulish', sans-serif;
    --primColor: rgb(6, 130, 118);
    --secColor: rgb(61, 59, 59);
    --accentColor: rgb(133, 133, 133);
    --backgroundColor: #fff;
    --lavender: #635bff;
    --grey: #dde3e3;
    --lightgreen: #86b500;
    --orange: #ff7d0c;
    --skyblue: #198cff;
    --maroon: #ff4a4a;
    --lightblue: #e4f0f8;
}

.primButton button,
.secButton button {
    height: 50px;
    border-radius: 50px;
}

.primButton.small button,
.secButton.small button {
    height: 40px;
    min-width: max-content;
}

.primButton.large button,
.secButton.large button {
    height: 60px;
}

::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: rgb(25, 157, 229, 15);
}

::selection {
    color: #fff;
    background: rgb(25, 157, 229, 15);
}

@media (max-width: 500px) {
    .primButton button,
    .secButton button,
    .primButton.small button,
    .secButton.small button {
        height: 45px;
        font-size: 13px;
    }
}
