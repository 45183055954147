.adminLayout {
    display: grid;
    grid-template-columns: 260px 3fr;
    height: 100vh;
}

.adminLayout .dashboardLayoutLt {
    background: #2b2b2b;
}

.adminLayout .dashboardLayoutRt {
    background: #fff;
    box-shadow: -1px 0 #e3e8ee;
}

.adminLayout .dashboardChild {
    padding: 30px 15px;
}
