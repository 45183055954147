body {
    margin: 0;
    font-family: var(--fontFamily), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--backgroundColor);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursorPointer {
    cursor: pointer;
}

h1 {
    font-size: 45px;
}

h2 {
    font-size: 28px;
}

h4 {
    font-size: 18px;
}

a {
    color: var(--primColor);
    font-weight: 500;
}

.customContainer {
    padding-left: 60px !important;
    padding-right: 60px !important;
    width: 100% !important;
}
.otpHelper {
    margin-top: -25px;
    text-align: left;
    font-size: 14px;
    margin-bottom: 20px;
    color: #686868;
    font-weight: 500;
}

div:focus,
button:focus {
    outline: 0;
}

.MuiButton-label {
    font-family: var(--fontFamily), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.MuiBadge-badge {
    background-color: var(--primColor) !important;
}

.customInput,
.customSelect,
.MuiFormControl-root.customDatepicker {
    margin-bottom: 30px;
}

.MuiFormControl-root.customDatepicker {
    width: 100%;
}

.customSelect .MuiFormControl-root {
    width: 100%;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--secColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--secColor);
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--secColor) !important;
    border-width: 1px !important;
}
.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--fontFamily) !important;
}

.cursorDecoy {
    cursor: pointer;
}

.customModal {
    z-index: 1500 !important;
}

.loaderCnt {
    background: #fff;
}

.fallbackLoader {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fallbackLoader img {
    border-radius: 50%;
}

.search .searchLt,
.dashboard .dashboardLt {
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
}

.search .searchRt,
.dashboard .dashboardRt {
    width: -webkit-fill-available;
}

.search .searchResults.Grid,
.dashboard .searchResults.Grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root {
    min-width: max-content;
    color: #8e8e8e;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active {
    color: var(--primColor);
}

.search .searchMiscFilters .customSelect,
.dashboard .searchMiscFilters .customSelect {
    margin-bottom: 0;
    min-width: 150px;
    margin-left: 25px;
}

.moveBack {
    color: #a6adb4;
}

.moveBack .material-icons {
    color: #979797;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #dfdfdf;
    margin-right: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.customBreadcrumbs ol {
    width: max-content;
}

.customBreadcrumbs a {
    color: #797979;
    text-transform: capitalize;
}

.customBreadcrumbs p {
    text-transform: capitalize;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: var(--primColor) !important;
}

.PrivateTabIndicator-colorPrimary-5 {
    background-color: var(--primColor) !important;
}

.customTabs {
    background: #fff !important;
    box-shadow: none !important;
    border-bottom: 1px solid #ccc !important;
}

.dialogActBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.customDialog .MuiDialogContent-root {
    padding-bottom: 15px;
}

.dialogActBtn .MuiButton-root {
    margin-right: 15px;
}

.MuiTabs-indicator {
    background-color: var(--primColor) !important;
}

.customTextArea .MuiFormControl-root {
    width: 100%;
}

.split_payment_button button {
    border-style: dashed !important;
    color: var(--primColor);
    padding: 8px;
}

.split_payment_close_payment {
    position: relative;
}

.split_payment_close_payment svg {
    position: absolute;
}

.react-flow__node-input {
    background: var(--primColor) !important;
    border: none !important;
    color: white !important;
}

.react-flow__node-default {
    background: var(--secColor) !important;
    border: none !important;
    color: white !important;
}

.chckBxRdrct a.hltTxt {
    color: var(--primColor);
    padding-left: 5px;
    cursor: pointer;
}

.spltPyWrpr {
    margin-bottom: 45px;
}

.errContainer {
    position: relative;
}

.errContainer .errrPg {
    position: absolute;
    bottom: -20px;
    left: 0;
    color: red;
}

.crdIcon {
    width: 22px !important;
}

.paymentTable .MuiTableCell-stickyHeader {
    min-width: 180px;
}

.wb_bw {
    word-break: break-word;
}

.csmPopScreen {
    max-width: 900px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
}

.rtTableWrapper {
    width: calc(100vw - 290px) !important;
}

.verifyPhone .phVer {
    display: block;
    margin: 10px 0;
}

.verifyPhone .phVerInp {
    margin-top: 25px;
}

.verifyPhone .actionWrapper.secondaryActions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}
.verifyPhone.customDialog .MuiPaper-root.MuiDialog-paper {
    width: 400px;
}

.verifyPhone.customDialog .MuiDialogContent-root {
    text-align: center;
}

.verifyPhone .phVer {
    display: block;
    margin-bottom: 25px;
}

.verifyPhone .primButton {
    width: max-content;
    margin: 0 auto;
    min-width: 140px;
}

.verifyPhone .phVerInp .customInput {
    margin-bottom: 10px;
}

.verifyPhone .secondaryActions button {
    margin-inline-end: 0 !important;
}

.verifyPhone .vpbTitle {
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 35px;
}

.verifyPhone .vpbTitle span {
    font-weight: 600;
}

.verifyPhone .actionWrapper {
    display: flex;
    margin-top: 15px;
    justify-content: center;
}

.refreshReport .primButton {
    width: max-content;
    margin-left: auto;
    min-width: 240px;
}

.reportFilesCnt {
    position: relative;
    margin-bottom: 20px;
}

.reportFiles {
    display: block;
    height: 100px;
    width: 100%;
    background: #f4f4f4;
    color: #434343;
    margin: 0;
    border-radius: 5px;
    border: 1px dashed #ccc;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.reportFiles p {
    margin: 10px 0 0 0;
    font-weight: 700;
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }
}
