.loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 99999;
}

.loaderWrapper img {
    width: 180px;
}
